import React, { useState, useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import LoadingIndicator from "react-loading-indicator";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment";
import { FaFilter } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import ELoader from "../eLoader";

const colorArray = {
  red: ["#F79696", "#D82424"],
  orange: ["#F2C293", "#F75C14"],
  green: ["#BBDABB", "#3D9A2F"],
};

export default function Reports() {
  const [cookies, setCookie, removeCookie] = useCookies("");
  const [auth, setAuth] = useState({ token: null, userid: null });
  const [refresh, setRefresh] = useState("No");
  const [tab, setTab] = useState("Attempts");
  const [mycourses, setMyCourses] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    start: "",
    startModal: false,
    end: "",
    endModal: false,
    result: "Any",
    course: "Any",
    sortby: "created_at",
    order: "ASC",
  });

  const [list, setList] = useState([]);

  const myCourses = () => {
    fetch(global.url + "api/user/myCourses", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({
        userid: auth.userid,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "OK") {
          setMyCourses(response.courses);
        } else if (response.status === "Failed") {
          toast.show(response.message, {
            type: "danger",
            placement: "bottom",
            duration: 4000,
            offset: 30,
            animationType: "slide-in",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getReports = (filter, tab) => {
    if (!loading) {
      setLoading(true);
      fetch(global.url + "api/user/getReportsV2", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          userid: auth.userid,
          tab: tab,
          filter: filter,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "OK") {
            setList(response.list);
          } else if (response.status === "Failed") {
            toast.show(response.message, {
              type: "danger",
              placement: "bottom",
              duration: 4000,
              offset: 30,
              animationType: "slide-in",
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const changeFilter = (col, val) => {
    var temp = filter;
    temp[col] = val;
    setFilter(temp);
    setRefresh(refresh === "No" ? "Yes" : "No");
    getReports(filter, tab);
  };

  const getGradeColor = (percentage, index) => {
    if (percentage === null || percentage === "") {
      percentage = 0;
    }
    if (percentage <= 40) {
      return colorArray.red[index];
    } else if (percentage > 40 && percentage <= 80) {
      return colorArray.orange[index];
    } else if (percentage > 80) {
      return colorArray.green[index];
    }
  };

  const changeTab = (tab) => {
    setList([]);
    setTab(tab);
    getReports(filter, tab);
  };

  var a = 0;
  const getUserAuth = async () => {
    var token = cookies.userToken;
    var userid = cookies.user.userid;
    var temp = auth;
    temp["token"] = token;
    temp["userid"] = userid;
    setAuth(temp);
    setRefresh(refresh === "Yes" ? "No" : "Yes");
    myCourses();
    getReports(filter, tab);
  };

  const checkPermission = async (furl, filename) => {};
  const getFileExtention = (fileUrl) => {
    // To get the file extension
    return /[.]/.exec(fileUrl) ? /[^.]+$/.exec(fileUrl) : undefined;
  };

  const [item, setItem] = useState(null);
  useEffect(() => {
    if (a === 0) {
      getUserAuth();
      a++;
    }
  }, []);

  return (
    <section className="userdashboard ">
      <div className="my100 float-left dashboxmain">
        <div className="sidebar-box float-left">
          <Sidebar act={10} />
        </div>
        <div className="dashcontent float-left">
          <TopBar heading="My Reports" />
          <div className="my100 dashwhitebox resultsbox">
            <div className="resultfiltside" >
              <div className="resultfilters w90 float-center">
                <div className="w90 float-center filterinbox">
                  <div className="container filterheading">
                    <p>Filters</p>{" "}
                    <FiX
                      className="pc_hide closfilt"
                      onClick={() =>
                        $(".resultfiltside").css("bottom", "-100%")
                      }
                    />
                  </div>
                  <div className="container filtersbox">
                    <div className="container filtbxhead">
                      <p>Attemp Date (Minimum)</p>
                    </div>
                    <div className="container filtervals">
                      <input
                        type="date"
                        onChange={(e) => changeFilter("start", e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="container filtersbox">
                    <div className="container filtbxhead">
                      <p>Attemp Date (Maximum)</p>
                    </div>
                    <div className="container filtervals">
                      <input
                        type="date"
                        onChange={(e) => changeFilter("end", e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="container filtersbox">
                    <div className="container filtbxhead">
                      <p>Result Generated?</p>
                    </div>
                    <div className="container filtervals">
                      <ul>
                        <li
                          className={
                            filter.result == "Any" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("result", "Any")}
                        >
                          Any
                        </li>
                        <li
                          className={
                            filter.result == "Yes" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("result", "Yes")}
                        >
                          Yes
                        </li>
                        <li
                          className={filter.result == "No" ? "selectedfil" : ""}
                          onClick={() => changeFilter("result", "No")}
                        >
                          No
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="container filtersbox">
                    <div className="container filtbxhead">
                      <p>Select Course</p>
                    </div>
                    <div className="container filtervals">
                      <ul>
                        <li
                          className={
                            filter.course == "Any" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("course", "Any")}
                        >
                          Any
                        </li>
                        {mycourses != "" &&
                          mycourses.map((myc, i) => (
                            <li
                              key={i}
                              className={
                                filter.course == myc.course_id ? "selectedfil" : ""
                              }
                              onClick={() => changeFilter("course", myc.course_id)}
                            >
                              {myc.name}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="container filtersbox">
                    <div className="container filtbxhead">
                      <p>Sort By</p>
                    </div>
                    <div className="container filtervals">
                      <ul>
                        <li
                          className={
                            filter.sortby === "created_at" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("sortby", "created_at")}
                        >
                          Date
                        </li>
                        <li
                          className={
                            filter.sortby === "name" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("sortby", "name")}
                        >
                          Name
                        </li>
                        <li
                          className={
                            filter.sortby === "percentage" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("sortby", "percentage")}
                        >
                          Marks
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="container filtersbox">
                    <div className="container filtbxhead">
                      <p>Sort Order</p>
                    </div>
                    <div className="container filtervals">
                      <ul>
                        <li
                          className={
                            filter.order === "ASC" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("order", "ASC")}
                        >
                          Ascending
                        </li>
                        <li
                          className={
                            filter.order === "DESC" ? "selectedfil" : ""
                          }
                          onClick={() => changeFilter("order", "DESC")}
                        >
                          Descending
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="container applyfilbutt pc_hide">
                    <div className="w50 float-left">
                      <div className="w95 float-left">
                        <button
                          className="applybut closfilt"
                          onClick={() =>
                            $(".resultfiltside").css("bottom", "-100%")
                          }
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div className="w50 float-left">
                      <div className="w95 float-right">
                        <button
                          className="cancbut closfilt"
                          onClick={() =>
                            $(".resultfiltside").css("bottom", "-100%")
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="resultdataside">
              <div className="w90 float-center">
                <div className="container">
                  <div className=" float-center tabs-top ">
                    <div
                      className={` float-left tabsmain ${
                        tab === "Attempts" ? "acttab2" : ""
                      }`}
                      onClick={() => changeTab("Attempts")}
                    >
                      <p>Test Results</p>
                    </div>
                    <div
                      className={` float-left tol tabsmain ${
                        tab === "Assignments" ? "acttab2" : ""
                      }`}
                      onClick={() => changeTab("Assignments")}
                    >
                      <p>Assignments</p>
                    </div>
                  </div>
                </div>
                {
                  // tab === "Attempts" &&
                  <div className="container">
                    <div className="headingGrid">
                      <div>
                        <strong>Test</strong>
                      </div>
                      <div></div>
                      <div className="right-text">Result</div>
                    </div>
                    {!loading && list.length == 0 && (
                      <div className="headingGrid">
                        <div></div>
                        <div>
                          <strong>No Result Found</strong>
                        </div>
                        <div></div>
                      </div>
                    )}
                    {!loading &&
                      list.length > 0 &&
                      list.map((item, index) => {
                        return (
                          <div
                            className="headingGrid"
                            key={tab + index}
                            onClick={() => setItem(item)}
                          >
                            <div className="imgBox">
                              <img src={global.asset + item.thumbnail} />
                            </div>
                            <div className="namesArea">
                              <p>
                                <strong>{item.testname}</strong>
                              </p>
                              <p>
                                <strong>Course:</strong> {item.course_name}
                              </p>
                              <p>
                                <strong>Attempt ID:</strong> {item.attemptid}
                              </p>
                              <p>
                                <strong>Date:</strong> {item.createddate}
                              </p>
                            </div>
                            <div
                              className="colorBox"
                              style={{
                                color: getGradeColor(item.percentage, 1),
                                backgroundColor: getGradeColor(
                                  item.percentage,
                                  0
                                ),
                              }}
                            >
                              {item.percentage === null ||
                              item.percentage === ""
                                ? "-"
                                : item.percentage + "%"}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                }

                {item !== null && tab == "Attempts" && (
                  <div className="resultPopup">
                    <div className="resultPopupInside">
                      <div className="popInside">
                        <div className="popHeading">
                          <div>
                            <strong>Test Result</strong>
                          </div>
                          <div
                            onClick={() => setItem(null)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-times"></i>
                          </div>
                        </div>
                        <div className="center-text">
                          {item.result != null && item.result != "" ? (
                            <>
                              <p
                                style={{
                                  padding: 10,
                                  color: "#000000",
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                You had scored {item.percentage}% ({item.marks}/
                                {item.outof})
                              </p>
                              <p
                                style={{
                                  color: "#000000",
                                  fontSize: 14,
                                  textAlign: "center",
                                }}
                              >
                                {item.grademessage}
                              </p>
                            </>
                          ) : (
                            <p
                              style={{
                                padding: 10,
                                color: "#000000",
                                fontSize: 16,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Result Not Generated Yet
                            </p>
                          )}
                        </div>
                        <div
                          className="container center-text"
                          style={{ marginTop: "10px" }}
                        >
                          <a
                            href={
                              "/dashboard/c/" +
                              item.course_id +
                              "/" +
                              item.purchase_id +
                              "/quiz/" +
                              item.contentid +
                              "?rpg=1"
                            }
                            target="_BLANK"
                          >
                            <strong>
                              VIEW QUIZ <i className="fas fa-arrow-right"></i>
                            </strong>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {item !== null && tab == "Assignments" && (
                  <div className="resultPopup">
                    <div className="resultPopupInside">
                      <div className="popInside">
                        <div className="popHeading">
                          <div>
                            <strong>Assignment Result</strong>
                          </div>
                          <div
                            onClick={() => setItem(null)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-times"></i>
                          </div>
                        </div>
                        <div className="center-text">
                          {item.result != null && item.result != "" ? (
                            <>
                              <p
                                style={{
                                  padding: 10,
                                  color: "#000000",
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                You had scored {item.percentage}% ({item.marks}/
                                {item.outof})
                              </p>
                              <p
                                style={{
                                  color: "#000000",
                                  fontSize: 14,
                                  textAlign: "center",
                                }}
                              >
                                {item.grademessage}
                              </p>
                            </>
                          ) : (
                            <p
                              style={{
                                padding: 10,
                                color: "#000000",
                                fontSize: 16,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              Result Not Generated Yet
                            </p>
                          )}
                        </div>
                        <div
                          className="container center-text"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="downloadGrid">
                            <div>
                              {item.answersheet !== "" && (
                                <a
                                  href={global.asset + item.answersheet}
                                  target="_BLANK"
                                >
                                  <strong>
                                    <i className="fas fa-download"></i> DOWNLOAD
                                    RESULT
                                  </strong>
                                </a>
                              )}
                            </div>
                            <a
                              href={
                                "/dashboard/c/" +
                                item.course_id +
                                "/" +
                                item.purchase_id +
                                "/assign/" +
                                item.contentid
                              }
                              target="_BLANK"
                            >
                              <strong>
                                VIEW ASSIGNMENT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </strong>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="showFilterIcon" onClick={() => {
        window.$(".resultfiltside").css("bottom", "0%")
      }}>
        <div className="filterIcon">
            <i className="fas fa-filter"></i>
        </div>
      </div>
    </section>
  );
}
